// src/components/SearchBar.js

import React from "react";
import "./SearchBar.css";

const SearchBar = ({ searchQuery, setSearchQuery, handleSearch }) => {
  return (
    <div className="search-bar">
      <div className="form" role="search">
        <label htmlFor="search">Nhập mã hàng cần tìm kiếm</label>
        <input
          id="search"
          type="search"
          placeholder="Nhập mã hàng cần tìm kiếm"
          autoFocus
          required
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
        />
        <button type="button" onClick={handleSearch}>
          Tìm kiếm
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
