import React, { useEffect, useState } from "react";
import SearchBar from "./components/SearchBar";
import "./App.css";
import "./components/Table.css";

function App() {
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState([]); // State để lưu nội dung bảng
  const [mobile, setMobile] = useState(window.innerWidth <= 500);
  const [isLoading, setIsLoading] = useState(false);

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 500);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      const response = await fetch("https://cam4k.vn/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ma_kho: searchQuery }), // Dữ liệu JSON gửi kèm
      });

      if (!response.ok) {
        setTableData([]); // Xóa dữ liệu cũ
        throw new Error("Có lỗi xảy ra khi gọi API");
      }

      const data = await response.json();
      const array = data.table_data;
      // Duyệt qua từng mảng con và xóa phần tử đầu tiên và cuối cùng
      array.forEach((subArray) => {
        if (subArray.length > 1) {
          // Kiểm tra nếu mảng con có ít nhất 2 phần tử
          subArray.splice(subArray.length - 3, 3); // Xóa phần tử cuối cùng
        }
      });
      setTableData(array);
    } catch (error) {
      console.error("Lỗi:", error);
    }
    setIsLoading(false);
  };

  console.log(mobile);

  return (
    <div className="app-container">
      <main className="app-main">
        <SearchBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          handleSearch={handleSearch}
        />
        {isLoading && (
          <div class="loader loader--style1" title="0">
            <svg
              version="1.1"
              id="loader-1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="80px"
              height="80px"
              viewBox="0 0 40 40"
              enable-background="new 0 0 40 40"
            >
              <path
                opacity="0.2"
                fill="#fff"
                d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
            s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
            c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
              />
              <path
                fill="#fff"
                d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
            C22.32,8.481,24.301,9.057,26.013,10.047z"
              >
                <animateTransform
                  attributeType="xml"
                  attributeName="transform"
                  type="rotate"
                  from="0 20 20"
                  to="360 20 20"
                  dur="0.9s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </div>
        )}
        {tableData.length && !isLoading && (
          <div className="container-table100">
            <div className="wrap-table100">
              <div className="table100">
                <table>
                  <thead>
                    <tr className="table100-head">
                      <th className="column1">STT</th>
                      <th className="column2">Mã vật tư</th>
                      <th className="column3">Quy cách</th>
                      <th className="column4">Lô</th>
                      <th className="column5">Phân loại SP</th>
                      <th className="column6">Tồn khả dụng</th>
                      <th className="column7">Kho</th>
                      <th className="column8">ĐVT</th>
                      <th className="column9">Tồn kho</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((row) => (
                      <tr>
                        {row.map((element, index) =>
                          mobile && !element ? null : (
                            <td className={"column" + (index + 1)}>
                              {element}
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </main>
      <footer className="app-footer">
        <p>© 2024 Bản quyền thuộc công ty phần mềm Cam4K.</p>
      </footer>
    </div>
  );
}

export default App;
